<template>
  <v-btn
    :color="color"
    :variant="depressed && 'flat'"
    :min-width="minWidth"
    class="font-weight-bold"
    size="x-large"
    v-bind="$attrs"
  >
    <slot>{{ tile }}</slot>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseBtn',

  props: {
    color: {
      type: String,
      default: 'primary',
    },
    depressed: {
      type: Boolean,
      default: true,
    },
    minWidth: {
      type: [Number, String],
      default: 164,
    },
    tile: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
