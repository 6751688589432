<template>
  <v-theme-provider>
    <base-section id="contact-us" class="bg-primary" space="20">
      <v-container>
        <v-row justify="center">
          <v-col cols="10" align-self="center">
            <h3 class="text-white">HAVE QUESTIONS? LET US KNOW!</h3>
          </v-col>

          <v-col cols="12" md="2">
            <base-btn block color="white" to="/contact" large outlined>
              Contact Us
            </base-btn>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>
